
<template>
    <div v-if="shouldDisplay" class="flex flex-col-reverse sm:flex-col mx-auto w-[300px] min-h-[250px]">
      <client-only>
        <iframe
          v-if="true"
          :src="iframeURL"
          :data-zone="zoneId"
          sandbox="allow-popups allow-scripts allow-same-origin allow-forms"
          scrolling="no"
          frameborder="0"
          class="w-[300px] h-[250px] shadow-2xl sm:shadow-none"
          loading="lazy"
        />
      </client-only>
      <div class="text-center pb-1">
        <ULink to="/advertise" class="text-xs text-gray-400 font-normal underline">Advertise on Western Montana News</ULink>
      </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  zoneId: number;
  mobileOnly?: boolean;
  desktopOnly?: boolean;
}>()

const route = useRoute()

const randomNumber = ref(Math.floor(Math.random() * 99999))
const mobileWidth = 768

const iframeURL = computed(() => `https://a.ads.rmbl.ws/loader?a=${props.zoneId}&r=${randomNumber.value}`)

const shouldDisplay = computed(() => {
  if (!import.meta.client) {
    return true
  }
  if (!props.mobileOnly && !props.desktopOnly) {
    return true
  }
  if (props.mobileOnly && window.innerWidth <= mobileWidth) {
    return true
  }
  if (props.desktopOnly && window.innerWidth >= mobileWidth) {
    return true
  }
  return false
})

// on route change update the random number
watch(() => route.path, () => {
  randomNumber.value = Math.floor(Math.random() * 99999)
})
</script>
